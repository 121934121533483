import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import RoleOnlyRoute from '../auth/RoleOnlyRoute';
import { Allreports } from '../pages/AllReports';
import { Dashboard } from '../pages/Dashboard';
import { DataUploadPage } from '../pages/DataUploadPage';
import { FAQPage } from '../pages/FAQPage';
import { ReportPage } from '../pages/ReportPage';
import { MainLayout } from '../pages/layouts/MainLayout';

const AppRoutes: React.FC = () => {
  return (
    <MainLayout>
      <Routes>
        <Route path=''>
          <Navigate to='/app/dashboard' />
        </Route>
        <Route path='dashboard'>
          <Dashboard />
        </Route>
        <RoleOnlyRoute path='data-delivery' roles={['upload']}>
          <DataUploadPage />
        </RoleOnlyRoute>
        <Route path='data-delivery/:id'>
          <DataUploadPage />
        </Route>
        <RoleOnlyRoute path='reports' roles={['view']}>
          <Allreports />
        </RoleOnlyRoute>
        <Route path='reports/:id'>
          <ReportPage />
        </Route>
        <Route path='faq'>
          <FAQPage />
        </Route>
      </Routes>
    </MainLayout>
  );
};

export default AppRoutes;
