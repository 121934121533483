export const blobToData = (blob: Blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export const base64ToBlob = (base64: string, type = 'application/octet-stream') =>
  fetch(`data:${type};base64,${base64}`).then((res) => res.blob());

export const bytesToString = (bytes: number) => {
  if (bytes == 0) {
    return '0.00 B';
  }
  const e = Math.floor(Math.log(bytes) / Math.log(1024));
  return (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + ' KMGTP'.charAt(e) + 'B';
};
