import { User } from 'oidc-client';

export type UserRole = 'view' | 'upload' | 'admin';
/**
 * This method will return true if the user has any roles that are not API.
 * @param user User object
 */
export const hasPortalAccess = (user: User | undefined): boolean => {
  try {
    if (user === undefined) return false;
    if (user.profile === undefined) return false;
    if (!user.profile?.extension_TRAHasPortalAccess) return false;
    return user.profile.extension_TRAHasPortalAccess === true;
  } catch {
    return false;
  }
};
/**
 * This method will return true if the user is able to view calculation.
 * @param user User object
 */
export const hasReportViewAccess = (user: User | undefined): boolean => {
  if (user === undefined) return false;
  if (!hasPortalAccess(user)) return false;
  if (!user.profile?.extension_TRAHasRoleReportViewer) return false;
  return user.profile.extension_TRAHasRoleReportViewer === true;
};

/**
 * This method will return whether the user is allowed to edit a calculation.
 * @param user User object
 */
export const hasUploadAccess = (user: User | undefined): boolean => {
  if (user === undefined) return false;
  if (!hasPortalAccess(user)) return false;
  if (!user.profile?.extension_TRAHasRoleUploader) return false;
  return user.profile.extension_TRAHasRoleUploader === true;
};

export const hasAdminAccess = (user: User | undefined): boolean => {
  if (user === undefined) return false;
  if (!hasPortalAccess(user)) return false;
  if (!user.profile?.extension_TRAHasRoleAdministrator) return false;
  return user.profile.extension_TRAHasRoleAdministrator === true;
};

export const userInRole = (user: User, roleName: UserRole) => {
  switch (roleName) {
    case 'view':
      return hasReportViewAccess(user);
    case 'upload':
      return hasUploadAccess(user);
    case 'admin':
      return hasAdminAccess(user);
  }
};
