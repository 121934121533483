import {
  ChartSquareBarIcon,
  CloudUploadIcon,
  HomeIcon,
  OfficeBuildingIcon,
  QuestionMarkCircleIcon,
  LightningBoltIcon,
} from '@heroicons/react/outline';
import { UserCircleIcon } from '@heroicons/react/outline';
import { User } from 'oidc-client';

import { userInRole } from '../auth/Roles';

interface MenuItem {
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  title: string;
  link: string;
}

export const getMenuItems = (user: User | undefined): MenuItem[] => {
  if (!user) {
    return [];
  }
  const menuItemList = [];
  menuItemList.push({
    icon: HomeIcon,
    title: 'Home',
    link: '/app/dashboard',
    roles: ['view', 'admin', 'upload'],
  });

  if (userInRole(user, 'upload')) {
    menuItemList.push({
      icon: CloudUploadIcon,
      title: 'Data uploaden',
      link: '/app/data-delivery',
    });
  }
  if (userInRole(user, 'view')) {
    menuItemList.push({
      icon: ChartSquareBarIcon,
      title: 'Rapportages',
      link: '/app/reports',
    });
  }
  if (userInRole(user, 'admin')) {
    menuItemList.push({
      icon: OfficeBuildingIcon,
      title: 'Organisaties',
      link: '/management/organizations',
    });
    menuItemList.push({
      icon: CloudUploadIcon,
      title: 'Uploadtaken',
      link: '/management/uploadtasks',
    });
    menuItemList.push({
      icon: UserCircleIcon,
      title: 'Medewerkers',
      link: '/management/employees',
    });
    menuItemList.push({
      icon: ChartSquareBarIcon,
      title: 'Rapportages',
      link: '/management/reports',
    });
    menuItemList.push({
      icon: LightningBoltIcon,
      title: 'Beheerders',
      link: '/management/adminusers',
    });
  }
  menuItemList.push({
    icon: QuestionMarkCircleIcon,
    title: 'Veelgestelde vragen',
    link: '/app/faq',
  });
  return menuItemList;
};

export type MenuType = 'desktop' | 'mobile';
