/* eslint-disable react/jsx-key */
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Column } from 'react-table';
import { stringDateToLocaleString } from 'utils/dateUtils';

import { Table, TableCellActiveIcon, TableCellChevronRight, TableCellInactiveIcon } from '../generic/Table';
import { EmployeeInfoDto } from '../services/apiGenerated';

export const EmployeesTableSimple: React.FC<{
  data: EmployeeInfoDto[] | undefined;
  isLoading: boolean;
  error?: FetchBaseQueryError | SerializedError;
}> = ({ data, isLoading, error }) => {
  const { t } = useTranslation();

  const onRowClick = (row: EmployeeInfoDto) => {
    navigate(`/management/employees/${row.id}`);
  };

  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/ban-types
  const columns: Column<EmployeeInfoDto>[] = [
    {
      Header: t('Voornaam').toString(),
      accessor: 'firstName',
    },
    {
      Header: t('Achternaam').toString(),
      accessor: 'lastName',
    },
    {
      Header: t('Rapportages').toString(),
      accessor: (row) => (row?.canViewReports === true ? TableCellActiveIcon : TableCellInactiveIcon),
    },
    {
      Header: t('Uploaden').toString(),
      accessor: (row) => (row?.canUploadData === true ? TableCellActiveIcon : TableCellInactiveIcon),
    },
    {
      Header: t('Laatst actief').toString(),
      accessor: (row) => {
        if (!row.lastLoggedIn) {
          return 'Nooit';
        }
        return stringDateToLocaleString(row.lastLoggedIn);
      },
    },
    {
      Header: t('Actief').toString(),
      accessor: (row) => (row.isActive ? TableCellActiveIcon : TableCellInactiveIcon),
    },
    {
      Header: ' ',
      accessor: () => TableCellChevronRight,
    },
  ];

  return <Table columns={columns} data={data ?? []} onRowClick={onRowClick} isLoading={isLoading} error={error} />;
};
