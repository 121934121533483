/* eslint-disable react/jsx-key */
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Column } from 'react-table';

import { Table, TableCellActiveIcon, TableCellChevronRight, TableCellInactiveIcon } from '../generic/Table';
import { useGetReportByOrganization } from '../services/api';
import { ReportInfoDto } from '../services/apiGenerated';

export const OrganizationReportsTable: React.FC<{ orgId: number }> = ({ orgId }: { orgId: number }) => {
  const { t } = useTranslation();
  const { data, error, isLoading } = useGetReportByOrganization({ organizationId: orgId });

  const onRowClick = (row: ReportInfoDto) => {
    navigate(`/management/reports/${row.id}`);
  };

  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/ban-types
  const columns: Column<ReportInfoDto>[] = [
    {
      Header: t('Rapportage').toString(),
      accessor: 'name',
    },
    {
      Header: t('Type').toString(),
      accessor: 'type',
    },
    {
      Header: t('Afdelingen').toString(),
      accessor: 'departmentCount',
    },
    {
      Header: t('Laatste wijziging').toString(),
      accessor: (row) => {
        if (!row.lastUpdatedDate) {
          return '';
        }
        return new Date(row.lastUpdatedDate).toLocaleString('nl-nl');
      },
    },
    {
      Header: t('Aangemaakt').toString(),
      accessor: (row) => {
        if (!row.creationDate) {
          return '';
        }
        return new Date(row.creationDate).toLocaleString('nl-nl');
      },
    },
    {
      Header: t('Actief').toString(),
      accessor: (row) => (row.isActive ? TableCellActiveIcon : TableCellInactiveIcon),
    },
    {
      Header: ' ',
      accessor: () => TableCellChevronRight,
    },
  ];

  return <Table columns={columns} data={data ?? []} onRowClick={onRowClick} isLoading={isLoading} error={error} />;
};
