/* eslint-disable @typescript-eslint/no-empty-function */
import { Switch } from '@headlessui/react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { CreateEmployeeDto, Employee } from 'features/services/apiGenerated';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import * as yup from 'yup';

import i18n from '../../i18n/config';
import { useGetDepartmentByOrganization } from '../services/api';
import { SelectCombo } from '../types';
import { PanelBase } from './components/PanelBase';
import { PanelContents } from './components/PanelContents';
import { PanelErrorFooter } from './components/PanelError';
import { PanelSubmit } from './components/PanelSubmit';

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .max(50, i18n.t('Naam mag minimaal 50 karakters bevatten'))
    .required(i18n.t('Dit veld is verplicht')),
  lastName: yup
    .string()
    .max(50, i18n.t('Naam mag minimaal 50 karakters bevatten'))
    .required(i18n.t('Dit veld is verplicht')),
  email: yup.string().email(i18n.t('Vul een geldig e-mailadres in')).required(i18n.t('Dit veld is verplicht')),
  departmentIds: yup
    .array()
    .of(yup.number().required())
    .test({
      message: i18n.t('U moet één of meer afdelingen selecteren').toString(),
      test: (arr) => arr !== undefined && arr.length > 0,
    })
    .strict()
    .required(),
  isActive: yup.bool().required(),
  canViewReports: yup.boolean().required(),
  canUploadData: yup
    .boolean()
    .required()
    .when('canViewReports', {
      is: (val: boolean) => !val, // alternatively: (val) => val == true
      then: yup.boolean().isTrue(i18n.t('De gebruiker moet in ieder geval 1 set rechten verkrijgen.')),
      otherwise: yup.boolean(),
    }),
  organizationId: yup.number(),
});

interface PanelProps {
  isOpened: boolean;
  onClose: () => void;
  onSubmit: (dto: CreateEmployeeDto) => void;
  title: string;
  description: string;
  employee?: Employee;
  orgId: number;
  isLoading: boolean;
  error?: SerializedError | FetchBaseQueryError | undefined;
}

export const EmployeeUpsertPanel: React.FC<PanelProps> = ({
  isOpened,
  onClose,
  onSubmit,
  title,
  description,
  employee,
  orgId,
  isLoading,
  error,
}: PanelProps) => {
  const onSliderClosed = () => {
    onClose();
  };
  const { t } = useTranslation();

  const animatedComponents = makeAnimated();
  const { data: departmentData } = useGetDepartmentByOrganization({ organizationId: orgId });
  const departments: SelectCombo[] = departmentData
    ? departmentData
        .filter((x) => x.id)
        .map((d) => {
          return {
            label: d.name ?? '',
            value: d.id ?? '',
          };
        })
    : [];

  const selectedValues: SelectCombo[] = [];
  const departmentIds: number[] = [];
  if (employee?.departments) {
    employee?.departments
      ?.map((d) => d.id)
      .forEach((i) => {
        if (i) {
          departmentIds.push(i);
          const found = departments?.find((x) => x && x.value === i);
          if (found) {
            selectedValues.push(found);
          }
        }
      });
  }
  return (
    <PanelBase isOpened={isOpened}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          firstName: employee?.firstName ?? '',
          lastName: employee?.lastName ?? '',
          email: employee?.email ?? '',
          isActive: employee?.isActive ?? false,
          departmentIds: departmentIds,
          canViewReports: employee?.isReportViewer ?? false,
          canUploadData: employee?.isDataUploader ?? false,
        }}
        onSubmit={(values) => {
          onSubmit({
            firstName: values.firstName,
            lastName: values.lastName,
            email: employee ? employee.email : values.email,
            isActive: values.isActive,
            departmentIds: values.departmentIds.filter((x) => x !== undefined) as number[],
            canViewReports: values.canViewReports,
            canUploadData: values.canUploadData,
            organizationId: orgId,
          });
        }}
      >
        {({ errors, touched, values, setValues }) => (
          <Form className='flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl'>
            <PanelContents title={title} description={description} onSliderClosed={onSliderClosed}>
              <div>
                <label htmlFor='firstName' className='block text-sm font-medium text-gray-900'>
                  {t('Voornaam')}
                </label>
                <div className='mt-1'>
                  <Field
                    type='text'
                    name='firstName'
                    id='firstName'
                    className={`block w-full border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-yellow-500 focus:border-yellow-500 ${
                      errors.firstName && touched.firstName ? 'border-red-500' : ''
                    }`}
                  />
                  {errors.firstName && touched.firstName && (
                    <span className='text-xs italic text-yellow-800'>{errors.firstName}</span>
                  )}
                </div>
              </div>
              <div>
                <label htmlFor='lastName' className='block text-sm font-medium text-gray-900'>
                  {t('Achternaam')}
                </label>
                <div className='mt-1'>
                  <Field
                    type='text'
                    name='lastName'
                    id='lastName'
                    className={`block w-full border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-yellow-500 focus:border-yellow-500 ${
                      errors.lastName && touched.lastName ? 'border-red-500' : ''
                    }`}
                  />
                  {errors.lastName && touched.lastName && (
                    <span className='text-xs italic text-yellow-800'>{errors.lastName}</span>
                  )}
                </div>
              </div>
              <div>
                <label htmlFor='email' className='block text-sm font-medium text-gray-900'>
                  {t('E-mailadres')}
                </label>
                <div className='mt-1'>
                  <Field
                    type='text'
                    name='email'
                    id='email'
                    disabled={employee !== undefined}
                    className={`block w-full border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-yellow-500 focus:border-yellow-500 
                                    ${employee !== undefined ? 'bg-gray-200 text-gray-700' : ''}
                                    ${errors.email && touched.email ? 'border-red-500' : ''}`}
                  />
                  {errors.email && touched.email && (
                    <span className='text-xs italic text-yellow-800'>{errors.email}</span>
                  )}
                </div>
              </div>

              <div>
                <div className='flex flex-col space-y-4'>
                  <label htmlFor='roles' className='block text-sm font-medium text-gray-900'>
                    {t('Rollen van de gebruiker')}
                  </label>
                  <div className='relative flex items-start'>
                    <div className='flex items-center h-5'>
                      <Field
                        id='canViewReports'
                        name='canViewReports'
                        type='checkbox'
                        className='w-4 h-4 text-yellow-600 border-gray-300 rounded focus:ring-yellow-500'
                        defaultChecked={values.canViewReports}
                      />
                    </div>
                    <div className='ml-3 text-sm'>
                      <label htmlFor='comments' className='font-semibold text-gray-700'>
                        {t('Rapportages')}
                      </label>
                      <p className='text-gray-500'>
                        {t('De gebruiker kan rapportages openen, weergeven en downloaden')}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className='relative flex items-start'>
                      <div className='flex items-center h-5'>
                        <Field
                          id='canUploadData'
                          name='canUploadData'
                          type='checkbox'
                          className='w-4 h-4 text-yellow-600 border-gray-300 rounded focus:ring-yellow-500'
                          defaultChecked={values.canUploadData}
                        />
                      </div>
                      <div className='ml-3 text-sm'>
                        <label htmlFor='candidates' className='font-semibold text-gray-700'>
                          {t('Uploaden')}
                        </label>
                        <p className='text-gray-500'>
                          {t('De gebruiker kan bestanden uploaden als input voor rapportages')}
                        </p>
                      </div>
                    </div>
                  </div>

                  {errors.canUploadData && touched.canUploadData && (
                    <span className='text-xs italic text-yellow-800'>{errors.canUploadData}</span>
                  )}
                </div>
              </div>
              <div>
                <label htmlFor='departmentIds' className='block text-sm font-medium text-gray-900'>
                  {t('Kies toegang tot afdeling')}
                </label>
                <div className='mt-1'>
                  <Select
                    defaultValue={selectedValues}
                    isMulti
                    components={animatedComponents}
                    name='departmentIds'
                    options={departments}
                    closeMenuOnSelect={false}
                    className='text-sm text-gray-900 border-gray-yellow'
                    onChange={(e) => {
                      setValues({ ...values, departmentIds: e?.map((x: SelectCombo) => x.value) });
                    }}
                  />
                  {errors.departmentIds && touched.departmentIds && (
                    <span className='text-xs italic text-yellow-800'>{errors.departmentIds}</span>
                  )}
                </div>
              </div>
              <div className='flex flex-row items-center'>
                <Switch
                  checked={values.isActive}
                  onChange={(value) =>
                    setValues({
                      ...values,
                      isActive: value,
                    })
                  }
                  className={`${
                    values.isActive ? 'bg-yellow-600' : 'bg-gray-200'
                  } relative inline-flex items-center h-6 rounded-full w-11`}
                >
                  <span
                    className={`${
                      values.isActive ? 'translate-x-6' : 'translate-x-1'
                    } inline-block w-4 h-4 transform bg-white rounded-full transition ease-in-out duration-200`}
                  />
                </Switch>
                <label htmlFor='isActive' className='ml-4 text-sm font-medium text-gray-900'>
                  {t('De gebruiker is actief')}
                </label>
              </div>
            </PanelContents>
            <PanelErrorFooter error={error} />
            <PanelSubmit isLoading={isLoading} onSliderClosed={onSliderClosed} />
          </Form>
        )}
      </Formik>
    </PanelBase>
  );
};
