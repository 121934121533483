import {
  UploadTaskStatus,
  OrganizationAuditLogType,
  SearchResultType,
  FileUploadStatus,
} from '../features/services/apiGenerated';
import i18n from './config';

export const getUploadStatusTranslation = (status?: UploadTaskStatus) => {
  if (!status) return i18n.t('Onbekend');
  switch (status) {
    case 'IsWaitingForUpload':
      return i18n.t('Wachten op upload');
    case 'IsBeingProcessed':
      return i18n.t('Bestanden worden gecontroleerd');
    case 'IsErrorAndReupload':
      return i18n.t('Fout bij controle');
    case 'ProcessingSuccesful':
      return i18n.t('Verwerking succesvol');
  }
};

export const getFileUploadStatusTranslation = (status?: FileUploadStatus) => {
  if (!status) return i18n.t('Onbekend');
  switch (status) {
    case 'UploadedByClient':
      return i18n.t('Klantupload');
    case 'UploadedByTra':
      return i18n.t('TRA Upload');
    case 'Processed':
      return i18n.t('Data verwerkt in database');
    case 'Error':
      return i18n.t('Fout bij verwerking');
  }
};

export const getSearchResultTranslation = (type?: SearchResultType) => {
  if (!type) return i18n.t('Onbekend');
  switch (type) {
    case 'Employee':
      return i18n.t('Medewerker');
    case 'Organization':
      return i18n.t('Organisatie');
    case 'Report':
      return i18n.t('Rapportage');
    case 'UploadTask':
      return i18n.t('Uploadverzoek');
  }
};
export const getOrganizationAuditTypeTranslation = (state?: OrganizationAuditLogType) => {
  if (!state) return i18n.t('Onbekend');
  switch (state) {
    case 'OrganizationCreated':
      return i18n.t('Organisatie aangemaakt');
    case 'OrganizationUpdated':
      return i18n.t('Organisatie gewijzigd');
    case 'UserAdded':
      return i18n.t('Gebruiker toegevoegd');
    case 'UserRemoved':
      return i18n.t('Gebruiker verwijderd');
    case 'UserModified':
      return i18n.t('Gebruiker gewijzigd');
    case 'DepartmentAdded':
      return i18n.t('Afdeling toegevoegd');
    case 'DepartmentRemoved':
      return i18n.t('Afdeling verwijderd');
    case 'DepartmentModified':
      return i18n.t('Afdeling gewijzigd');
    case 'DepartmentReportAdded':
      return i18n.t('Rapportage aan afdeling toegevoegd');
    case 'DepartmentReportRemoved':
      return i18n.t('Rapportage uit afdeling verwijderd');
    case 'UploadTaskAdded':
      return i18n.t('Uploadtaak toegevoegd');
    case 'UploadTaskRemoved':
      return i18n.t('Uploadtaak verwijderd');
    case 'UploadTaskModified':
      return i18n.t('Uploadtaak gewijzigd');
    case 'ReportCreated':
      return i18n.t('Rapportage aangemaakt');
    case 'ReportModified':
      return i18n.t('Rapportage gewijzigd');
    case 'ReportRemoved':
      return i18n.t('Rapportage verwijderd');
    case 'ReportDistributed':
      return i18n.t('Rapportage via mail verstuurd');
  }
};
