/* eslint-disable react/jsx-key */
import { Modal } from 'features/generic/Modal';
import saveAs from 'file-saver';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { stringDateToLocaleString } from 'utils/dateUtils';

import { getFileUploadStatusTranslation } from '../../i18n/enumTranslations';
import { base64ToBlob, bytesToString } from '../../utils/fileHelpers';
import { Table, TableCellDownload } from '../generic/Table';
import { useGetUploadTaskById, useGetUploadTaskFile } from '../services/api';
import { FileUpload } from '../services/apiGenerated';

export const UploadTaskUploadsTable: React.FC<{
  uploadTaskId: number;
  view: 'customer' | 'management';
}> = ({ uploadTaskId, view }) => {
  const { t } = useTranslation();
  const { data, isLoading, error } = useGetUploadTaskById({ id: uploadTaskId });
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState<FileUpload>();
  const [getUploadedFile, result] = useGetUploadTaskFile();

  const onRowClick = (row: FileUpload) => {
    setSelectedFile(row);
    if (row.status === 'Error') {
      setShowErrorModal(true);
    } else {
      getUploadedFile({ taskId: uploadTaskId, fileId: row?.id ?? '' });
    }
  };
  const fileResult = result.data;
  useEffect(() => {
    async function downloadFile() {
      if (fileResult?.fileContents) {
        let mimeType = '';
        switch (selectedFile?.fileExtension) {
          case 'csv':
            mimeType = 'text/csv;';
            break;
          case 'txt':
            mimeType = 'text/plain;';
            break;
          default:
            mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;';
        }
        console.log('got a result', fileResult);
        const blob = await base64ToBlob(fileResult?.fileContents, mimeType);
        saveAs(blob, `${selectedFile?.fileName}.${selectedFile?.fileExtension}`);
      }
    }
    downloadFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileResult]);

  //   const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/ban-types
  const columns: Column<FileUpload>[] = [
    {
      Header: t('Bestandsnaam').toString(),
      accessor: 'fileName',
    },
    {
      Header: t('Extensie').toString(),
      accessor: 'fileExtension',
    },
    {
      Header: t('Grootte').toString(),
      accessor: (row) => bytesToString(row?.fileSize ?? 0),
    },
    {
      Header: t('Status').toString(),
      accessor: (row) => getFileUploadStatusTranslation(row.status),
    },
    {
      Header: t('Uploader').toString(),
      accessor: 'lastModifiedBy',
    },
    {
      Header: t('Geupload op').toString(),
      accessor: (row) => {
        if (!row.lastModifiedDate) {
          return '';
        }
        return stringDateToLocaleString(row.lastModifiedDate);
      },
    },
    {
      Header: ' ',
      accessor: () => TableCellDownload,
    },
  ];

  let filteredData: FileUpload[] = [];
  if (data?.uploads && view == 'customer') {
    filteredData = data?.uploads.filter((x) => x.status === 'UploadedByClient');
  }
  if (data?.uploads && view == 'management') {
    filteredData = data?.uploads.filter((x) => x.status !== 'UploadedByClient');
  }
  return (
    <>
      <Table columns={columns} onRowClick={onRowClick} data={filteredData ?? []} isLoading={isLoading} error={error} />
      <Modal
        isOpened={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        type='error'
        title={t('Fout bij verwerken')}
        description={selectedFile?.errorDescription ?? ''}
        actionButtonText={t('Terug')}
      />
    </>
  );
};
