import { apiBaseQuery as api } from './apiBaseQuery';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiAdminEmployee: build.query<GetApiAdminEmployeeApiResponse, GetApiAdminEmployeeApiArg>({
      query: () => ({ url: `/api/AdminEmployee` }),
    }),
    postApiAdminEmployee: build.mutation<PostApiAdminEmployeeApiResponse, PostApiAdminEmployeeApiArg>({
      query: (queryArg) => ({
        url: `/api/AdminEmployee`,
        method: 'POST',
        params: { FirstName: queryArg.firstName, LastName: queryArg.lastName, Email: queryArg.email },
      }),
    }),
    getApiAdminEmployeeById: build.query<GetApiAdminEmployeeByIdApiResponse, GetApiAdminEmployeeByIdApiArg>({
      query: (queryArg) => ({ url: `/api/AdminEmployee/${queryArg.id}` }),
    }),
    deleteApiAdminEmployeeById: build.mutation<DeleteApiAdminEmployeeByIdApiResponse, DeleteApiAdminEmployeeByIdApiArg>(
      {
        query: (queryArg) => ({ url: `/api/AdminEmployee/${queryArg.id}`, method: 'DELETE' }),
      }
    ),
    postApiV1Contact: build.mutation<PostApiV1ContactApiResponse, PostApiV1ContactApiArg>({
      query: (queryArg) => ({ url: `/api/v1/Contact`, method: 'POST', body: queryArg.contactFormSubmissionDto }),
    }),
    getApiV1DepartmentById: build.query<GetApiV1DepartmentByIdApiResponse, GetApiV1DepartmentByIdApiArg>({
      query: (queryArg) => ({ url: `/api/v1/Department/${queryArg.id}` }),
    }),
    putApiV1DepartmentById: build.mutation<PutApiV1DepartmentByIdApiResponse, PutApiV1DepartmentByIdApiArg>({
      query: (queryArg) => ({ url: `/api/v1/Department/${queryArg.id}`, method: 'PUT', body: queryArg.department }),
    }),
    deleteApiV1DepartmentById: build.mutation<DeleteApiV1DepartmentByIdApiResponse, DeleteApiV1DepartmentByIdApiArg>({
      query: (queryArg) => ({ url: `/api/v1/Department/${queryArg.id}`, method: 'DELETE' }),
    }),
    getApiV1DepartmentByDepartmentIdReports: build.query<
      GetApiV1DepartmentByDepartmentIdReportsApiResponse,
      GetApiV1DepartmentByDepartmentIdReportsApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/Department/${queryArg.departmentId}/Reports` }),
    }),
    getApiV1DepartmentByDepartmentIdEmployees: build.query<
      GetApiV1DepartmentByDepartmentIdEmployeesApiResponse,
      GetApiV1DepartmentByDepartmentIdEmployeesApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/Department/${queryArg.departmentId}/Employees` }),
    }),
    postApiV1Department: build.mutation<PostApiV1DepartmentApiResponse, PostApiV1DepartmentApiArg>({
      query: (queryArg) => ({ url: `/api/v1/Department`, method: 'POST', body: queryArg.department }),
    }),
    getApiV1Employee: build.query<GetApiV1EmployeeApiResponse, GetApiV1EmployeeApiArg>({
      query: () => ({ url: `/api/v1/Employee` }),
    }),
    postApiV1Employee: build.mutation<PostApiV1EmployeeApiResponse, PostApiV1EmployeeApiArg>({
      query: (queryArg) => ({ url: `/api/v1/Employee`, method: 'POST', body: queryArg.createEmployeeDto }),
    }),
    getApiV1EmployeeByEmployeeIdDepartments: build.query<
      GetApiV1EmployeeByEmployeeIdDepartmentsApiResponse,
      GetApiV1EmployeeByEmployeeIdDepartmentsApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/Employee/${queryArg.employeeId}/Departments` }),
    }),
    getApiV1EmployeeByEmployeeIdReports: build.query<
      GetApiV1EmployeeByEmployeeIdReportsApiResponse,
      GetApiV1EmployeeByEmployeeIdReportsApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/Employee/${queryArg.employeeId}/Reports` }),
    }),
    getApiV1EmployeeById: build.query<GetApiV1EmployeeByIdApiResponse, GetApiV1EmployeeByIdApiArg>({
      query: (queryArg) => ({ url: `/api/v1/Employee/${queryArg.id}` }),
    }),
    putApiV1EmployeeById: build.mutation<PutApiV1EmployeeByIdApiResponse, PutApiV1EmployeeByIdApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/Employee/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.createEmployeeDto,
      }),
    }),
    deleteApiV1EmployeeById: build.mutation<DeleteApiV1EmployeeByIdApiResponse, DeleteApiV1EmployeeByIdApiArg>({
      query: (queryArg) => ({ url: `/api/v1/Employee/${queryArg.id}`, method: 'DELETE' }),
    }),
    postApiV1EmployeeAdministrator: build.mutation<
      PostApiV1EmployeeAdministratorApiResponse,
      PostApiV1EmployeeAdministratorApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/Employee/Administrator`,
        method: 'POST',
        body: queryArg.createAdministratorDto,
      }),
    }),
    getApiV1Organization: build.query<GetApiV1OrganizationApiResponse, GetApiV1OrganizationApiArg>({
      query: () => ({ url: `/api/v1/Organization` }),
    }),
    postApiV1Organization: build.mutation<PostApiV1OrganizationApiResponse, PostApiV1OrganizationApiArg>({
      query: (queryArg) => ({ url: `/api/v1/Organization`, method: 'POST', body: queryArg.createOrganizationDto }),
    }),
    getApiV1OrganizationMe: build.query<GetApiV1OrganizationMeApiResponse, GetApiV1OrganizationMeApiArg>({
      query: () => ({ url: `/api/v1/Organization/me` }),
    }),
    getApiV1OrganizationByOrganizationIdDepartments: build.query<
      GetApiV1OrganizationByOrganizationIdDepartmentsApiResponse,
      GetApiV1OrganizationByOrganizationIdDepartmentsApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/Organization/${queryArg.organizationId}/Departments` }),
    }),
    getApiV1OrganizationByOrganizationIdReports: build.query<
      GetApiV1OrganizationByOrganizationIdReportsApiResponse,
      GetApiV1OrganizationByOrganizationIdReportsApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/Organization/${queryArg.organizationId}/Reports` }),
    }),
    getApiV1OrganizationByOrganizationIdEmployees: build.query<
      GetApiV1OrganizationByOrganizationIdEmployeesApiResponse,
      GetApiV1OrganizationByOrganizationIdEmployeesApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/Organization/${queryArg.organizationId}/Employees` }),
    }),
    getApiV1OrganizationByOrganizationIdUploadTasks: build.query<
      GetApiV1OrganizationByOrganizationIdUploadTasksApiResponse,
      GetApiV1OrganizationByOrganizationIdUploadTasksApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/Organization/${queryArg.organizationId}/UploadTasks` }),
    }),
    getApiV1OrganizationById: build.query<GetApiV1OrganizationByIdApiResponse, GetApiV1OrganizationByIdApiArg>({
      query: (queryArg) => ({ url: `/api/v1/Organization/${queryArg.id}` }),
    }),
    putApiV1OrganizationById: build.mutation<PutApiV1OrganizationByIdApiResponse, PutApiV1OrganizationByIdApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/Organization/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateOrganizationDto,
      }),
    }),
    deleteApiV1OrganizationById: build.mutation<
      DeleteApiV1OrganizationByIdApiResponse,
      DeleteApiV1OrganizationByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/Organization/${queryArg.id}`, method: 'DELETE' }),
    }),
    getApiV1Report: build.query<GetApiV1ReportApiResponse, GetApiV1ReportApiArg>({
      query: () => ({ url: `/api/v1/Report` }),
    }),
    postApiV1Report: build.mutation<PostApiV1ReportApiResponse, PostApiV1ReportApiArg>({
      query: (queryArg) => ({ url: `/api/v1/Report`, method: 'POST', body: queryArg.createReportDto }),
    }),
    getApiV1ReportByReportIdDepartments: build.query<
      GetApiV1ReportByReportIdDepartmentsApiResponse,
      GetApiV1ReportByReportIdDepartmentsApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/Report/${queryArg.reportId}/Departments` }),
    }),
    getApiV1ReportPowerBi: build.query<GetApiV1ReportPowerBiApiResponse, GetApiV1ReportPowerBiApiArg>({
      query: () => ({ url: `/api/v1/Report/PowerBI` }),
    }),
    getApiV1ReportByReportIdEmployees: build.query<
      GetApiV1ReportByReportIdEmployeesApiResponse,
      GetApiV1ReportByReportIdEmployeesApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/Report/${queryArg.reportId}/Employees` }),
    }),
    getApiV1ReportByIdPowerBiEmbed: build.query<
      GetApiV1ReportByIdPowerBiEmbedApiResponse,
      GetApiV1ReportByIdPowerBiEmbedApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/Report/${queryArg.id}/PowerBIEmbed` }),
    }),
    getApiV1ReportByIdFileEmbed: build.query<GetApiV1ReportByIdFileEmbedApiResponse, GetApiV1ReportByIdFileEmbedApiArg>(
      {
        query: (queryArg) => ({ url: `/api/v1/Report/${queryArg.id}/FileEmbed` }),
      }
    ),
    getApiV1ReportById: build.query<GetApiV1ReportByIdApiResponse, GetApiV1ReportByIdApiArg>({
      query: (queryArg) => ({ url: `/api/v1/Report/${queryArg.id}` }),
    }),
    putApiV1ReportById: build.mutation<PutApiV1ReportByIdApiResponse, PutApiV1ReportByIdApiArg>({
      query: (queryArg) => ({ url: `/api/v1/Report/${queryArg.id}`, method: 'PUT', body: queryArg.createReportDto }),
    }),
    deleteApiV1ReportById: build.mutation<DeleteApiV1ReportByIdApiResponse, DeleteApiV1ReportByIdApiArg>({
      query: (queryArg) => ({ url: `/api/v1/Report/${queryArg.id}`, method: 'DELETE' }),
    }),
    postApiV1ReportByReportIdDistribute: build.mutation<
      PostApiV1ReportByReportIdDistributeApiResponse,
      PostApiV1ReportByReportIdDistributeApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/Report/${queryArg.reportId}/Distribute`, method: 'POST' }),
    }),
    getApiV1Search: build.query<GetApiV1SearchApiResponse, GetApiV1SearchApiArg>({
      query: () => ({ url: `/api/v1/Search` }),
    }),
    getApiV1UploadTask: build.query<GetApiV1UploadTaskApiResponse, GetApiV1UploadTaskApiArg>({
      query: () => ({ url: `/api/v1/UploadTask` }),
    }),
    postApiV1UploadTask: build.mutation<PostApiV1UploadTaskApiResponse, PostApiV1UploadTaskApiArg>({
      query: (queryArg) => ({ url: `/api/v1/UploadTask`, method: 'POST', body: queryArg.uploadTask }),
    }),
    getApiV1UploadTaskById: build.query<GetApiV1UploadTaskByIdApiResponse, GetApiV1UploadTaskByIdApiArg>({
      query: (queryArg) => ({ url: `/api/v1/UploadTask/${queryArg.id}` }),
    }),
    putApiV1UploadTaskById: build.mutation<PutApiV1UploadTaskByIdApiResponse, PutApiV1UploadTaskByIdApiArg>({
      query: (queryArg) => ({ url: `/api/v1/UploadTask/${queryArg.id}`, method: 'PUT', body: queryArg.uploadTask }),
    }),
    deleteApiV1UploadTaskById: build.mutation<DeleteApiV1UploadTaskByIdApiResponse, DeleteApiV1UploadTaskByIdApiArg>({
      query: (queryArg) => ({ url: `/api/v1/UploadTask/${queryArg.id}`, method: 'DELETE' }),
    }),
    getApiV1UploadTaskByTaskIdFileAndFileId: build.query<
      GetApiV1UploadTaskByTaskIdFileAndFileIdApiResponse,
      GetApiV1UploadTaskByTaskIdFileAndFileIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/UploadTask/${queryArg.taskId}/File/${queryArg.fileId}` }),
    }),
    postApiV1UploadTaskByIdUploadFile: build.mutation<
      PostApiV1UploadTaskByIdUploadFileApiResponse,
      PostApiV1UploadTaskByIdUploadFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/UploadTask/${queryArg.id}/UploadFile`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postApiV1UploadTaskByTaskIdDistribute: build.mutation<
      PostApiV1UploadTaskByTaskIdDistributeApiResponse,
      PostApiV1UploadTaskByTaskIdDistributeApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/UploadTask/${queryArg.taskId}/Distribute`, method: 'POST' }),
    }),
    putApiV1UploadTaskByIdStatus: build.mutation<
      PutApiV1UploadTaskByIdStatusApiResponse,
      PutApiV1UploadTaskByIdStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/UploadTask/${queryArg.id}/Status`,
        method: 'PUT',
        body: queryArg.uploadTaskStatusDto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type GetApiAdminEmployeeApiResponse = /** status 200 Success */ AdminEmployeeInfoDto[];
export type GetApiAdminEmployeeApiArg = void;
export type PostApiAdminEmployeeApiResponse = unknown;
export type PostApiAdminEmployeeApiArg = {
  firstName?: string;
  lastName?: string;
  email?: string;
};
export type GetApiAdminEmployeeByIdApiResponse = /** status 200 Success */ AdminEmployeeInfoDto;
export type GetApiAdminEmployeeByIdApiArg = {
  id: string;
};
export type DeleteApiAdminEmployeeByIdApiResponse = unknown;
export type DeleteApiAdminEmployeeByIdApiArg = {
  id: string;
};
export type PostApiV1ContactApiResponse = /** status 200 Success */ void;
export type PostApiV1ContactApiArg = {
  contactFormSubmissionDto: ContactFormSubmissionDto;
};
export type GetApiV1DepartmentByIdApiResponse = /** status 200 Success */ Department;
export type GetApiV1DepartmentByIdApiArg = {
  id: number;
};
export type PutApiV1DepartmentByIdApiResponse = /** status 204 No Content */ void;
export type PutApiV1DepartmentByIdApiArg = {
  id: number;
  department: Department;
};
export type DeleteApiV1DepartmentByIdApiResponse = /** status 204 No Content */ void;
export type DeleteApiV1DepartmentByIdApiArg = {
  id: number;
};
export type GetApiV1DepartmentByDepartmentIdReportsApiResponse = /** status 200 Success */ ReportInfoDto[];
export type GetApiV1DepartmentByDepartmentIdReportsApiArg = {
  departmentId: number;
};
export type GetApiV1DepartmentByDepartmentIdEmployeesApiResponse = /** status 200 Success */ EmployeeInfoDto[];
export type GetApiV1DepartmentByDepartmentIdEmployeesApiArg = {
  departmentId: number;
};
export type PostApiV1DepartmentApiResponse = /** status 201 Created */ Department;
export type PostApiV1DepartmentApiArg = {
  department: Department;
};
export type GetApiV1EmployeeApiResponse = /** status 200 Success */ EmployeeInfoDto[];
export type GetApiV1EmployeeApiArg = void;
export type PostApiV1EmployeeApiResponse = /** status 201 Created */ Employee;
export type PostApiV1EmployeeApiArg = {
  createEmployeeDto: CreateEmployeeDto;
};
export type GetApiV1EmployeeByEmployeeIdDepartmentsApiResponse = /** status 200 Success */ DepartmentInfoDto[];
export type GetApiV1EmployeeByEmployeeIdDepartmentsApiArg = {
  employeeId: number;
};
export type GetApiV1EmployeeByEmployeeIdReportsApiResponse = /** status 200 Success */ ReportInfoDto[];
export type GetApiV1EmployeeByEmployeeIdReportsApiArg = {
  employeeId: number;
};
export type GetApiV1EmployeeByIdApiResponse = /** status 200 Success */ Employee;
export type GetApiV1EmployeeByIdApiArg = {
  id: number;
};
export type PutApiV1EmployeeByIdApiResponse = /** status 204 No Content */ void;
export type PutApiV1EmployeeByIdApiArg = {
  id: number;
  createEmployeeDto: CreateEmployeeDto;
};
export type DeleteApiV1EmployeeByIdApiResponse = /** status 204 No Content */ void;
export type DeleteApiV1EmployeeByIdApiArg = {
  id: number;
};
export type PostApiV1EmployeeAdministratorApiResponse = /** status 201 Created */ void;
export type PostApiV1EmployeeAdministratorApiArg = {
  createAdministratorDto: CreateAdministratorDto;
};
export type GetApiV1OrganizationApiResponse = /** status 200 Success */ OrganizationInfoDto[];
export type GetApiV1OrganizationApiArg = void;
export type PostApiV1OrganizationApiResponse = /** status 201 Created */ Organization;
export type PostApiV1OrganizationApiArg = {
  createOrganizationDto: CreateOrganizationDto;
};
export type GetApiV1OrganizationMeApiResponse = /** status 200 Success */ OrganizationBasicInfoDto;
export type GetApiV1OrganizationMeApiArg = void;
export type GetApiV1OrganizationByOrganizationIdDepartmentsApiResponse = /** status 200 Success */ DepartmentInfoDto[];
export type GetApiV1OrganizationByOrganizationIdDepartmentsApiArg = {
  organizationId: number;
};
export type GetApiV1OrganizationByOrganizationIdReportsApiResponse = /** status 200 Success */ ReportInfoDto[];
export type GetApiV1OrganizationByOrganizationIdReportsApiArg = {
  organizationId: number;
};
export type GetApiV1OrganizationByOrganizationIdEmployeesApiResponse = /** status 200 Success */ EmployeeInfoDto[];
export type GetApiV1OrganizationByOrganizationIdEmployeesApiArg = {
  organizationId: number;
};
export type GetApiV1OrganizationByOrganizationIdUploadTasksApiResponse = /** status 200 Success */ EmployeeInfoDto[];
export type GetApiV1OrganizationByOrganizationIdUploadTasksApiArg = {
  organizationId: number;
};
export type GetApiV1OrganizationByIdApiResponse = /** status 200 Success */ Organization;
export type GetApiV1OrganizationByIdApiArg = {
  id: number;
};
export type PutApiV1OrganizationByIdApiResponse = /** status 204 No Content */ void;
export type PutApiV1OrganizationByIdApiArg = {
  id: number;
  updateOrganizationDto: UpdateOrganizationDto;
};
export type DeleteApiV1OrganizationByIdApiResponse = /** status 204 No Content */ void;
export type DeleteApiV1OrganizationByIdApiArg = {
  id: number;
};
export type GetApiV1ReportApiResponse = /** status 200 Success */ ReportInfoDto[];
export type GetApiV1ReportApiArg = void;
export type PostApiV1ReportApiResponse = /** status 201 Created */ OrganizationReport;
export type PostApiV1ReportApiArg = {
  createReportDto: CreateReportDto;
};
export type GetApiV1ReportByReportIdDepartmentsApiResponse = /** status 200 Success */ DepartmentInfoDto[];
export type GetApiV1ReportByReportIdDepartmentsApiArg = {
  reportId: string;
};
export type GetApiV1ReportPowerBiApiResponse = /** status 200 Success */ Report[];
export type GetApiV1ReportPowerBiApiArg = void;
export type GetApiV1ReportByReportIdEmployeesApiResponse = /** status 200 Success */ EmployeeInfoDto[];
export type GetApiV1ReportByReportIdEmployeesApiArg = {
  reportId: string;
};
export type GetApiV1ReportByIdPowerBiEmbedApiResponse = /** status 200 Success */ PowerBiEmbedParams;
export type GetApiV1ReportByIdPowerBiEmbedApiArg = {
  id: string;
};
export type GetApiV1ReportByIdFileEmbedApiResponse = /** status 200 Success */ FileEmbedParams;
export type GetApiV1ReportByIdFileEmbedApiArg = {
  id: string;
};
export type GetApiV1ReportByIdApiResponse = /** status 200 Success */ OrganizationReport;
export type GetApiV1ReportByIdApiArg = {
  id: string;
};
export type PutApiV1ReportByIdApiResponse = /** status 204 No Content */ void;
export type PutApiV1ReportByIdApiArg = {
  id: string;
  createReportDto: CreateReportDto;
};
export type DeleteApiV1ReportByIdApiResponse = /** status 204 No Content */ void;
export type DeleteApiV1ReportByIdApiArg = {
  id: string;
};
export type PostApiV1ReportByReportIdDistributeApiResponse = /** status 200 Success */ void;
export type PostApiV1ReportByReportIdDistributeApiArg = {
  reportId: string;
};
export type GetApiV1SearchApiResponse = /** status 200 Success */ SearchResult[];
export type GetApiV1SearchApiArg = void;
export type GetApiV1UploadTaskApiResponse = /** status 200 Success */ UploadTaskInfoDto[];
export type GetApiV1UploadTaskApiArg = void;
export type PostApiV1UploadTaskApiResponse = /** status 201 Created */ UploadTask;
export type PostApiV1UploadTaskApiArg = {
  uploadTask: UploadTask;
};
export type GetApiV1UploadTaskByIdApiResponse = /** status 200 Success */ UploadTaskInfoDto;
export type GetApiV1UploadTaskByIdApiArg = {
  id: number;
};
export type PutApiV1UploadTaskByIdApiResponse = /** status 204 No Content */ void;
export type PutApiV1UploadTaskByIdApiArg = {
  id: number;
  uploadTask: UploadTask;
};
export type DeleteApiV1UploadTaskByIdApiResponse = /** status 204 No Content */ void;
export type DeleteApiV1UploadTaskByIdApiArg = {
  id: number;
};
export type GetApiV1UploadTaskByTaskIdFileAndFileIdApiResponse = /** status 200 Success */ UploadTaskFile;
export type GetApiV1UploadTaskByTaskIdFileAndFileIdApiArg = {
  taskId: number;
  fileId: string;
};
export type PostApiV1UploadTaskByIdUploadFileApiResponse = /** status 201 Created */ UploadTask;
export type PostApiV1UploadTaskByIdUploadFileApiArg = {
  id: number;
  body: UploadTaskFileUploadDto[];
};
export type PostApiV1UploadTaskByTaskIdDistributeApiResponse = /** status 200 Success */ void;
export type PostApiV1UploadTaskByTaskIdDistributeApiArg = {
  taskId: number;
};
export type PutApiV1UploadTaskByIdStatusApiResponse = /** status 204 No Content */ void;
export type PutApiV1UploadTaskByIdStatusApiArg = {
  id: number;
  uploadTaskStatusDto: UploadTaskStatusDto;
};
export type AdminEmployeeInfoDto = {
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  isActive?: boolean;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type ContactFormSubmissionDto = {
  phoneNumber?: string | null;
  message?: string | null;
};
export type ReportType = 'PowerBI' | 'PDF' | 'Excel';
export type OrganizationReport = {
  id?: string;
  name?: string | null;
  description?: string | null;
  departments?: Department[] | null;
  type?: ReportType;
  processingError?: string | null;
  isActive?: boolean;
  isDeleted?: boolean;
  reportSource?: string | null;
  reportContainer?: string | null;
  organizationId?: number;
  organization?: Organization;
  views?: number;
  isDistributed?: boolean;
  creationDate?: string;
  lastModifiedDate?: string;
  createdBy?: string | null;
  lastModifiedBy?: string | null;
};
export type FileUploadStatus = 'UploadedByClient' | 'UploadedByTra' | 'Processed' | 'Error';
export type FileUpload = {
  id?: string;
  fileSize?: number;
  fileLocation?: string | null;
  status?: FileUploadStatus;
  storageContainerName?: string | null;
  folderName?: string | null;
  fileName?: string | null;
  fileExtension?: string | null;
  errorDescription?: string | null;
  creationDate?: string;
  lastModifiedDate?: string;
  createdBy?: string | null;
  lastModifiedBy?: string | null;
};
export type UploadTaskStatus = 'IsWaitingForUpload' | 'IsBeingProcessed' | 'IsErrorAndReupload' | 'ProcessingSuccesful';
export type UploadTaskLog = {
  id?: number;
  action?: string | null;
  description?: string | null;
  creationDate?: string;
  lastModifiedDate?: string;
  createdBy?: string | null;
  lastModifiedBy?: string | null;
};
export type UploadTask = {
  id?: number;
  reportName?: string | null;
  description?: string | null;
  uploads?: FileUpload[] | null;
  organizationId?: number;
  organization?: Organization;
  status?: UploadTaskStatus;
  processingError?: string | null;
  dueDate?: string;
  auditLogs?: UploadTaskLog[] | null;
  isActive?: boolean;
  isDistributed?: boolean;
  creationDate?: string;
  lastModifiedDate?: string;
  createdBy?: string | null;
  lastModifiedBy?: string | null;
};
export type OrganizationAuditLogType =
  | 'OrganizationCreated'
  | 'OrganizationUpdated'
  | 'UserAdded'
  | 'UserRemoved'
  | 'UserModified'
  | 'DepartmentAdded'
  | 'DepartmentRemoved'
  | 'DepartmentModified'
  | 'DepartmentReportAdded'
  | 'DepartmentReportRemoved'
  | 'UploadTaskAdded'
  | 'UploadTaskRemoved'
  | 'UploadTaskModified'
  | 'UploadTaskDistributed'
  | 'ReportCreated'
  | 'ReportModified'
  | 'ReportRemoved'
  | 'ReportDistributed';
export type OrganizationAuditLog = {
  id?: number;
  type?: OrganizationAuditLogType;
  description?: string | null;
  creationDate?: string;
  lastModifiedDate?: string;
  createdBy?: string | null;
  lastModifiedBy?: string | null;
};
export type BrandingType = 'LogoAndName' | 'LogoOnly' | 'NameOnly';
export type Organization = {
  id?: number;
  name?: string | null;
  logoUri?: string | null;
  reports?: OrganizationReport[] | null;
  departments?: Department[] | null;
  employees?: Employee[] | null;
  uploadTasks?: UploadTask[] | null;
  auditLogs?: OrganizationAuditLog[] | null;
  brandingType?: BrandingType;
  isActive?: boolean;
  isDeleted?: boolean;
  creationDate?: string;
  lastModifiedDate?: string;
  createdBy?: string | null;
  lastModifiedBy?: string | null;
};
export type Employee = {
  id?: number;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  azureObjectId?: string;
  lastLoggedIn?: string | null;
  departments?: Department[] | null;
  organizationId?: number | null;
  organization?: Organization;
  isActive?: boolean;
  isReportViewer?: boolean;
  isDataUploader?: boolean;
  creationDate?: string;
  lastModifiedDate?: string;
  createdBy?: string | null;
  lastModifiedBy?: string | null;
};
export type Department = {
  id?: number;
  name?: string | null;
  departmentEmployees?: Employee[] | null;
  reports?: OrganizationReport[] | null;
  organizationId?: number;
  organization?: Organization;
  isActive?: boolean;
  isDeleted?: boolean;
  creationDate?: string;
  lastModifiedDate?: string;
  createdBy?: string | null;
  lastModifiedBy?: string | null;
};
export type ReportInfoDto = {
  id?: string;
  name?: string | null;
  description?: string | null;
  departmentCount?: number;
  organizationName?: string | null;
  employeeCount?: number;
  type?: ReportType;
  creationDate?: string;
  lastUpdatedDate?: string;
  isActive?: boolean;
};
export type EmployeeInfoDto = {
  id?: number;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  organizationName?: string | null;
  departmentCount?: number;
  isActive?: boolean;
  canViewReports?: boolean;
  canUploadData?: boolean;
  creationDate?: string;
  lastModifiedDate?: string;
  lastLoggedIn?: string | null;
};
export type CreateEmployeeDto = {
  organizationId?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  isActive?: boolean;
  canViewReports?: boolean;
  canUploadData?: boolean;
  departmentIds?: number[] | null;
};
export type DepartmentInfoDto = {
  id?: number;
  name?: string | null;
  organizationId?: number;
  organizationName?: string | null;
  reportCount?: number;
  employeeCount?: number;
  lastModifiedDate?: string;
  isActive?: boolean;
};
export type CreateAdministratorDto = {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
};
export type OrganizationInfoDto = {
  id?: number;
  name?: string | null;
  logoUri?: string | null;
  brandingType?: BrandingType;
  departmentCount?: number;
  employeeCount?: number;
  reportCount?: number;
  creationDate?: string;
  lastModifiedDate?: string;
  isActive?: boolean;
};
export type CreateOrganizationDto = {
  name?: string | null;
  logoContents?: string | null;
  brandingType?: BrandingType;
  isActive?: boolean;
};
export type OrganizationBasicInfoDto = {
  name?: string | null;
  logoUri?: string | null;
  brandingType?: BrandingType;
  userLastActive?: string | null;
};
export type UpdateOrganizationDto = {
  name?: string | null;
  logoContents?: string | null;
  brandingType?: BrandingType;
  isActive?: boolean;
};
export type CreateReportDto = {
  name?: string | null;
  description?: string | null;
  isActive?: boolean;
  type?: ReportType;
  departmentIds?: number[] | null;
  powerBIReportId?: string | null;
  uploadedFileName?: string | null;
  uploadedFileContents?: string | null;
  organizationId?: number;
};
export type ReportUserAccessRight = object;
export type PrincipalType = object;
export type ServicePrincipalProfile = {
  id?: string;
  displayName?: string | null;
};
export type ReportUser = {
  reportUserAccessRight?: ReportUserAccessRight;
  emailAddress?: string | null;
  displayName?: string | null;
  identifier?: string | null;
  graphId?: string | null;
  userType?: string | null;
  principalType?: PrincipalType;
  profile?: ServicePrincipalProfile;
};
export type SubscriptionUser = {
  emailAddress?: string | null;
  displayName?: string | null;
  identifier?: string | null;
  graphId?: string | null;
  userType?: string | null;
  principalType?: PrincipalType;
  profile?: ServicePrincipalProfile;
};
export type Subscription = {
  id?: string;
  title?: string | null;
  artifactId?: string | null;
  artifactDisplayName?: string | null;
  subArtifactDisplayName?: string | null;
  artifactType?: string | null;
  isEnabled?: boolean | null;
  frequency?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  linkToContent?: boolean | null;
  previewImage?: boolean | null;
  attachmentFormat?: string | null;
  users?: SubscriptionUser[] | null;
};
export type Report = {
  id?: string;
  name?: string | null;
  datasetId?: string | null;
  appId?: string | null;
  description?: string | null;
  reportType?: string | null;
  originalReportId?: string | null;
  webUrl?: string | null;
  embedUrl?: string | null;
  users?: ReportUser[] | null;
  subscriptions?: Subscription[] | null;
};
export type EmbedToken = {
  token?: string | null;
  tokenId?: string;
  expiration?: string;
};
export type PowerBiEmbedParams = {
  embedToken?: EmbedToken;
  embedUrl?: string | null;
};
export type FileEmbedParams = {
  fileContents?: string | null;
  type?: ReportType;
};
export type SearchResultType = 'Organization' | 'Employee' | 'UploadTask' | 'Report';
export type SearchResult = {
  type?: SearchResultType;
  name?: string | null;
  id?: string | null;
};
export type UploadTaskInfoDto = {
  dataUploaders?: number;
  id?: number;
  reportName?: string | null;
  description?: string | null;
  uploads?: FileUpload[] | null;
  organizationId?: number;
  organization?: Organization;
  status?: UploadTaskStatus;
  processingError?: string | null;
  dueDate?: string;
  auditLogs?: UploadTaskLog[] | null;
  isActive?: boolean;
  isDistributed?: boolean;
  creationDate?: string;
  lastModifiedDate?: string;
  createdBy?: string | null;
  lastModifiedBy?: string | null;
};
export type UploadTaskFile = {
  fileContents?: string | null;
};
export type UploadTaskFileUploadDto = {
  taskId?: number;
  filename?: string | null;
  fileContents?: string | null;
  fileExtension?: string | null;
  fileSize?: number;
};
export type UploadTaskStatusDto = {
  taskId?: number;
  newStatus?: UploadTaskStatus;
  optionalReason?: string | null;
};
export const {
  useGetApiAdminEmployeeQuery,
  usePostApiAdminEmployeeMutation,
  useGetApiAdminEmployeeByIdQuery,
  useDeleteApiAdminEmployeeByIdMutation,
  usePostApiV1ContactMutation,
  useGetApiV1DepartmentByIdQuery,
  usePutApiV1DepartmentByIdMutation,
  useDeleteApiV1DepartmentByIdMutation,
  useGetApiV1DepartmentByDepartmentIdReportsQuery,
  useGetApiV1DepartmentByDepartmentIdEmployeesQuery,
  usePostApiV1DepartmentMutation,
  useGetApiV1EmployeeQuery,
  usePostApiV1EmployeeMutation,
  useGetApiV1EmployeeByEmployeeIdDepartmentsQuery,
  useGetApiV1EmployeeByEmployeeIdReportsQuery,
  useGetApiV1EmployeeByIdQuery,
  usePutApiV1EmployeeByIdMutation,
  useDeleteApiV1EmployeeByIdMutation,
  usePostApiV1EmployeeAdministratorMutation,
  useGetApiV1OrganizationQuery,
  usePostApiV1OrganizationMutation,
  useGetApiV1OrganizationMeQuery,
  useGetApiV1OrganizationByOrganizationIdDepartmentsQuery,
  useGetApiV1OrganizationByOrganizationIdReportsQuery,
  useGetApiV1OrganizationByOrganizationIdEmployeesQuery,
  useGetApiV1OrganizationByOrganizationIdUploadTasksQuery,
  useGetApiV1OrganizationByIdQuery,
  usePutApiV1OrganizationByIdMutation,
  useDeleteApiV1OrganizationByIdMutation,
  useGetApiV1ReportQuery,
  usePostApiV1ReportMutation,
  useGetApiV1ReportByReportIdDepartmentsQuery,
  useGetApiV1ReportPowerBiQuery,
  useGetApiV1ReportByReportIdEmployeesQuery,
  useGetApiV1ReportByIdPowerBiEmbedQuery,
  useGetApiV1ReportByIdFileEmbedQuery,
  useGetApiV1ReportByIdQuery,
  usePutApiV1ReportByIdMutation,
  useDeleteApiV1ReportByIdMutation,
  usePostApiV1ReportByReportIdDistributeMutation,
  useGetApiV1SearchQuery,
  useGetApiV1UploadTaskQuery,
  usePostApiV1UploadTaskMutation,
  useGetApiV1UploadTaskByIdQuery,
  usePutApiV1UploadTaskByIdMutation,
  useDeleteApiV1UploadTaskByIdMutation,
  useGetApiV1UploadTaskByTaskIdFileAndFileIdQuery,
  usePostApiV1UploadTaskByIdUploadFileMutation,
  usePostApiV1UploadTaskByTaskIdDistributeMutation,
  usePutApiV1UploadTaskByIdStatusMutation,
} = injectedRtkApi;
