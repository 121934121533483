import { Menu, Transition } from '@headlessui/react';
import { SearchIcon } from '@heroicons/react/solid';
import { useGetSearchPossibilities } from 'features/services/api';
import { getSearchResultTranslation } from 'i18n/enumTranslations';
import { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { twClassNames } from 'utils/twClassNames';

import { RootState } from '../../app/rootReducer';
import { hasAdminAccess } from '../auth/Roles';
import { SearchResult } from '../services/apiGenerated';

export const TopNavSearch = () => {
  const { data: searches } = useGetSearchPossibilities();
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth);
  const handleSearch = (value: string) => {
    setSearchValue(value);
  };
  const { t } = useTranslation();
  const goToResult = (result: SearchResult) => {
    if (!result) return;
    if (hasAdminAccess(user)) {
      switch (result.type) {
        case 'Employee':
          navigate(`/management/employees/${result.id}`);
          break;
        case 'Organization':
          navigate(`/management/organizations/${result.id}`);
          break;
        case 'Report':
          navigate(`/management/reports/${result.id}`);
          break;
        case 'UploadTask':
          navigate(`/management/uploadTasks/${result.id}`);
          break;
      }
    } else {
      switch (result.type) {
        case 'Report':
          navigate(`/app/reports/${result.id}`);
          break;
        case 'UploadTask':
          navigate(`/app/data-delivery/${result.id}`);
          break;
        default:
          break;
      }
    }
    setSearchValue('');
    if (searchInput.current) {
      searchInput.current.value = '';
    }
  };
  const searchResults: SearchResult[] = [];
  if (searchValue) {
    const searchTerms = searchValue.split(' ');
    searchTerms.forEach((searchPart) => {
      const results = searches?.filter((allItems) => allItems.name?.toLowerCase()?.includes(searchPart.toLowerCase()));
      if (results) searchResults.push(...results);
    });
  }
  const searchResultSet = Array.from(new Set(searchResults)).splice(0, 10);

  const searchInput = useRef<HTMLInputElement>(null);
  return (
    <div className='flex w-full md:ml-0'>
      <label htmlFor='search_field' className='sr-only'>
        Search
      </label>
      <div className='relative w-full text-gray-400 focus-within:text-gray-600'>
        <div className='absolute inset-y-0 left-0 flex items-center pointer-events-none'>
          <SearchIcon className='w-5 h-5' aria-hidden='true' />
        </div>
        <input
          id='search_field'
          className='block w-full h-full py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 border-transparent focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm'
          placeholder={t('Zoeken...')}
          type='input'
          onChange={(e) => handleSearch(e.target.value)}
          autoComplete='off'
          autoCapitalize='off'
          ref={searchInput}
        />
        <Menu as='div' className='relative inline-block text-left'>
          {() => (
            <>
              <div>
                {/* <Menu.Button className='inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-yellow-600 border border-yellow-300 rounded-md shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-yellow-500'></Menu.Button> */}
              </div>

              <Transition
                show={searchValue.length > 0 && searchResults.length > 0}
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
              >
                <Menu.Items
                  static
                  className='absolute left-0 z-50 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg w-96 -top-5 ring-1 ring-black ring-opacity-5 focus:outline-none'
                >
                  {searchResultSet.map((item) => {
                    return (
                      <div className='py-1' key={`${item.name}${item.id}`}>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={twClassNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'group flex items-center px-4 py-2 text-sm w-full space-x-4'
                              )}
                              onClick={() => goToResult(item)}
                            >
                              <span className='text-yellow-500'>●</span>
                              <span className='flex-grow font-semibold text-left'>{item.name}</span>
                              <span className='italic'>({getSearchResultTranslation(item.type)})</span>
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    );
                  })}
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    </div>
  );
};
