/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
*/
import { CheckCircleIcon, MailIcon, PhoneIcon, XCircleIcon } from '@heroicons/react/outline';
import { useSendContactForm } from 'features/services/api';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootState } from '../../app/rootReducer';
import { Card } from '../generic/Card';

export const ContactForm = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.auth);
  const [message, setMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [formSent, setFormSent] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [
    sendContactForm, // This is the mutation trigger // This is the destructured mutation result
    { isLoading: isSubmitting },
  ] = useSendContactForm();

  const handleSubmitForm = () => {
    if (!message) {
      setHasError(true);
      return;
    } else {
      sendContactForm({
        contactFormSubmissionDto: {
          phoneNumber: phone,
          message: message,
        },
      })
        .unwrap()
        .then(() => {
          setFormSent(true);
        });
    }
  };
  useEffect(() => {
    if (message) {
      setHasError(false);
    }
  }, [message]);

  return (
    <Card className='border-b-4 border-b-yellow-500'>
      <div className='relative bg-white '>
        <div className='absolute inset-0'>
          <div className='absolute inset-y-0 left-0 w-1/2 bg-gray-50' />
        </div>
        <div className='relative mx-auto max-w-7xl lg:grid lg:grid-cols-5'>
          <div className='px-4 py-16 bg-gray-50 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12'>
            <div className='max-w-lg mx-auto'>
              <h2 className='text-2xl font-semibold tracking-tight text-gray-900 sm:text-3xl'>
                {t('Neem contact op')}
              </h2>
              <p className='mt-3 text-lg leading-6 text-gray-500'>
                {t('Onze supportafdeling zit klaar om uw vragen te beantwoorden!')}
              </p>
              <dl className='mt-8 text-base text-gray-500'>
                <div>
                  <dt className='sr-only'>{t('Adres')}</dt>
                  <dd>
                    <p> {t('The Reporting Agency B.V.')}</p>
                    <p> {t('Atoomweg 63')}</p>
                    <p> {t('3542 AA Utrecht')}</p>
                  </dd>
                </div>
                <div className='mt-6'>
                  <dt className='sr-only'>{t('Telefoon')}</dt>
                  <dd className='flex'>
                    <PhoneIcon className='flex-shrink-0 w-6 h-6 text-gray-400' aria-hidden='true' />
                    <span className='ml-3'>+31 6 28 64 23 94</span>
                  </dd>
                </div>
                <div className='mt-3'>
                  <dt className='sr-only'>{t('E-mail')}</dt>
                  <dd className='flex'>
                    <MailIcon className='flex-shrink-0 w-6 h-6 text-gray-400' aria-hidden='true' />
                    <span className='ml-3'>info@thereportingagency.nl</span>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className='px-4 py-16 bg-white sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12'>
            <div className='max-w-lg mx-auto lg:max-w-none'>
              <div className='grid grid-cols-1 gap-y-6'>
                {hasError && (
                  <div className='p-4 rounded-md bg-red-50'>
                    <div className='flex'>
                      <div className='flex-shrink-0'>
                        <XCircleIcon className='w-5 h-5 text-red-400' aria-hidden='true' />
                      </div>
                      <div className='ml-3'>
                        <h3 className='text-sm font-medium text-red-800'>{t('Fout bij verzenden')}</h3>
                        <div className='mt-2 text-sm text-red-700'>
                          <ul className='pl-5 space-y-1 list-disc'>
                            <li>{t('Bericht is verplicht.')}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {formSent ? (
                  <div className='p-4 rounded-md bg-green-50'>
                    <div className='flex'>
                      <div className='flex-shrink-0'>
                        <CheckCircleIcon className='w-5 h-5 text-green-400' aria-hidden='true' />
                      </div>
                      <div className='ml-3'>
                        <p className='text-sm font-medium text-green-800'>
                          Uw vraag is verzonden en we zullen zo spoedig mogelijk contact met u opnemen.
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div>
                      <label htmlFor='full_name' className='sr-only'>
                        {t('Uw naam')}*
                      </label>
                      <input
                        type='text'
                        name='full_name'
                        id='full_name'
                        disabled={true}
                        defaultValue={user?.profile?.name}
                        autoComplete='name'
                        className='block w-full px-4 py-3 placeholder-gray-500 bg-gray-100 border-gray-300 rounded-md shadow-sm cursor-not-allowed focus:ring-indigo-500 focus:border-indigo-500'
                        placeholder={t('Uw naam')}
                      />
                    </div>
                    <div>
                      <label htmlFor='email' className='sr-only'>
                        {t('E-mail')}*
                      </label>
                      <input
                        id='email'
                        disabled={true}
                        name='email'
                        type='email'
                        autoComplete='email'
                        defaultValue={user?.profile?.emails[0]}
                        className='block w-full px-4 py-3 placeholder-gray-500 bg-gray-100 border-gray-300 rounded-md shadow-sm cursor-not-allowed focus:ring-indigo-500 focus:border-indigo-500'
                        placeholder={t('E-mail')}
                      />
                    </div>
                    <div>
                      <label htmlFor='phone' className='sr-only'>
                        {t('Telefoon')}
                      </label>
                      <input
                        type='text'
                        name='phone'
                        id='phone'
                        autoComplete='tel'
                        className='block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-yellow-500 focus:border-yellow-500'
                        placeholder={t('Telefoon')}
                        defaultValue={''}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                    <div>
                      <label htmlFor='message' className='sr-only'>
                        {t('Vraag / opmerking')}
                      </label>
                      <textarea
                        id='message'
                        name='message'
                        rows={4}
                        className='block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-yellow-500 focus:border-yellow-500'
                        placeholder={t('Vraag / opmerking')}
                        defaultValue={''}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                    <div>
                      {isSubmitting && (
                        <button
                          type='submit'
                          className='inline-flex justify-center px-6 py-3 text-base font-medium text-white bg-yellow-600 border border-transparent rounded-md shadow-sm cursor-not-allowed bg-400 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                          disabled={true}
                        >
                          {t('Bezig met verzenden')}
                        </button>
                      )}
                      {!isSubmitting && (
                        <button
                          type='submit'
                          className='inline-flex justify-center px-6 py-3 text-base font-medium text-white bg-yellow-600 border border-transparent rounded-md shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                          onClick={() => handleSubmitForm()}
                        >
                          {t('Verzenden')}
                        </button>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
