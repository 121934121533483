/* eslint-disable react/jsx-key */
import { useGetOrganization } from 'features/services/api';
import { OrganizationAuditLog } from 'features/services/apiGenerated';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { stringDateToLocaleString } from 'utils/dateUtils';

import { getOrganizationAuditTypeTranslation } from '../../i18n/enumTranslations';
import { Table } from '../generic/Table';

export const OrganizationAuditTable: React.FC<{ orgId: number }> = ({
  orgId,
}: PropsWithChildren<{ orgId: number }>) => {
  const { t } = useTranslation();
  const { data, error, isLoading } = useGetOrganization({ id: orgId });

  // id?: number;
  // type?: OrganizationAuditLogType;
  // description?: string | null;
  // creationDate?: string;
  // lastModifiedDate?: string;
  // createdBy?: string | null;
  // lastModifiedBy?: string | null;

  const columns: Column<OrganizationAuditLog>[] = [
    {
      Header: t('Datum').toString(),
      accessor: (row) => {
        if (!row.creationDate) {
          return 'Niet bekend';
        }
        return stringDateToLocaleString(row.creationDate);
      },
    },
    {
      Header: t('Gebruiker').toString(),
      accessor: 'lastModifiedBy',
    },
    {
      Header: t('Type').toString(),
      accessor: (row) => getOrganizationAuditTypeTranslation(row.type),
    },
    {
      Header: t('Extra informatie').toString(),
      accessor: (row) => row.description,
    },
  ];
  if (error && 'status' in error && error.status === 404) {
    return <></>;
  }
  return <Table columns={columns} data={data?.auditLogs ?? []} isLoading={isLoading} error={error} />;
};
