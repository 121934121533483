import { Card } from 'features/generic/Card';
import { OrganizationAuditTable } from 'features/organizations/OrganizationAuditTable';
import { OrganizationReportsTable } from 'features/reports/ReportsTableOrganizations';
import { useGetOrganization } from 'features/services/api';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { twClassNames } from 'utils/twClassNames';

import { OrganizationDepartmentsTable } from '../../departments/DepartmentsTableOrganizations';
import { OrganizationEmployeesTable } from '../../employees/EmployeesTableOrganizations';
import { OrganizationHeader } from '../../organizations/OrganizationHeader';
import { UploadTasksTable } from '../../uploadTasks/UploadTasksTable';

export type OrganizationTabPage = 'departments' | 'uploadTasks' | 'reports' | 'employees' | 'auditLogs';
interface TabProp {
  tab?: OrganizationTabPage;
}
interface Tab {
  name: string;
  type: OrganizationTabPage;
  count?: number;
}

export const AdminOrganizationPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<OrganizationTabPage>(
    ((location?.state as TabProp)?.tab as OrganizationTabPage) ?? 'reports'
  );
  const orgId = Number.parseInt(id?.toString() ?? '');
  const { data, isLoading } = useGetOrganization({ id: orgId });

  if (!id || Number.isNaN(Number.parseInt(id))) {
    navigate('/management/organizations');
    return <></>;
  }
  const tabs: Tab[] = [
    { name: t('Rapportages'), type: 'reports', count: data?.reports?.length ?? 0 },
    { name: t('Data Uploads'), type: 'uploadTasks', count: data?.uploadTasks?.length ?? 0 },
    { name: t('Medewerkers'), type: 'employees', count: data?.employees?.length ?? 0 },
    { name: t('Afdelingen'), type: 'departments', count: data?.departments?.length ?? 0 },
    { name: t('Historie'), type: 'auditLogs', count: data?.auditLogs?.length ?? 0 },
  ];

  const onItemAdded = (page: OrganizationTabPage) => {
    setActiveTab(page);
  };
  return (
    <main className='relative z-0 flex-1 pb-8 overflow-y-auto'>
      <OrganizationHeader orgId={orgId} onItemAdded={onItemAdded} />

      <div className='mt-8'>
        <div className='max-w-6xl px-4 mx-auto mt-8 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8'>
          <Card className='px-4 py-6 bg-white sm:px-6 lg:px-8'>
            <div className='sm:hidden'>
              <label htmlFor='tabs' className='sr-only'>
                {t('Kies pagina')}
              </label>
              <select
                id='tabs'
                name='tabs'
                className='block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm'
                defaultValue={tabs.find((tab) => tab.type === activeTab)?.name}
                onChange={(e) => setActiveTab(e.target.value as OrganizationTabPage)}
              >
                {tabs.map((tab) => (
                  <option key={tab.name} value={tab.type}>
                    {tab.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='hidden sm:block'>
              <div className='border-b border-gray-200'>
                <nav className='flex -mb-px space-x-8' aria-label='Tabs'>
                  {tabs.map((tab) => (
                    <button
                      key={tab.name}
                      onClick={() => setActiveTab(tab.type)}
                      className={twClassNames(
                        tab.type === activeTab
                          ? 'border-yellow-500 text-yellow-600'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm focus:outline-none'
                      )}
                      aria-current={tab.type === activeTab ? 'page' : undefined}
                    >
                      {tab.name}
                      {isLoading && (
                        <svg
                          className='w-4 h-4 ml-3 text-yellow-700 animate-spin'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                        >
                          <circle
                            className='opacity-25'
                            cx='12'
                            cy='12'
                            r='10'
                            stroke='currentColor'
                            strokeWidth='4'
                          ></circle>
                          <path
                            className='opacity-75'
                            fill='currentColor'
                            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                          ></path>
                        </svg>
                      )}
                      {!isLoading ? (
                        <span
                          className={twClassNames(
                            tab.type === activeTab ? 'bg-yellow-100 text-yellow-600' : 'bg-gray-100 text-gray-900',
                            'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                          )}
                        >
                          {tab.count}
                        </span>
                      ) : null}
                    </button>
                  ))}
                </nav>
              </div>
            </div>

            <div className='mt-4'>
              {activeTab === 'reports' && <OrganizationReportsTable orgId={orgId} />}
              {activeTab === 'employees' && <OrganizationEmployeesTable orgId={orgId} />}
              {activeTab === 'departments' && <OrganizationDepartmentsTable orgId={orgId} />}
              {activeTab === 'auditLogs' && <OrganizationAuditTable orgId={orgId} />}
              {activeTab === 'uploadTasks' && <UploadTasksTable orgId={orgId} />}
            </div>
          </Card>
        </div>
      </div>
    </main>
  );
};
