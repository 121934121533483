/* eslint-disable react/jsx-key */
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Column } from 'react-table';
import { stringDateToLocaleString } from 'utils/dateUtils';

import { Table, TableCellChevronRight } from '../generic/Table';
import { DepartmentInfoDto } from '../services/apiGenerated';

export const SimpleDepartmentsTable: React.FC<{
  data: DepartmentInfoDto[] | undefined;
  isLoading: boolean;
  error?: FetchBaseQueryError | SerializedError;
}> = ({ data, isLoading, error }) => {
  const { t } = useTranslation();

  const onRowClick = (row: DepartmentInfoDto) => {
    navigate(`/management/departments/${row.id}`);
  };

  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/ban-types
  const columns: Column<DepartmentInfoDto>[] = [
    {
      Header: t('Afdeling').toString(),
      accessor: 'name',
    },
    {
      Header: t('Medewerkers').toString(),
      accessor: 'employeeCount',
    },
    {
      Header: t('Aantal Rapportages').toString(),
      accessor: 'reportCount',
    },
    {
      Header: t('Laatste wijziging').toString(),
      accessor: (row) => {
        if (!row.lastModifiedDate) {
          return '';
        }
        return stringDateToLocaleString(row.lastModifiedDate);
      },
    },
    // {
    //   Header: t('Actief').toString(),
    //   accessor: (row) => (row.isActive ? TableCellActiveIcon : TableCellInactiveIcon),
    // },
    {
      Header: ' ',
      accessor: () => TableCellChevronRight,
    },
  ];

  return <Table columns={columns} data={data ?? []} onRowClick={onRowClick} isLoading={isLoading} error={error} />;
};
