/* eslint-disable @typescript-eslint/no-empty-function */
import { TrashIcon } from '@heroicons/react/outline';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { CreateOrganizationDto, Organization } from 'features/services/apiGenerated';
import { Formik, Form, Field } from 'formik';
import i18n from 'i18n/config';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { isValidHttpUrl } from 'utils/baseUrl';
import { getBase64 } from 'utils/fileUploads';
import * as yup from 'yup';

import { BrandingType } from '../services/apiGenerated';
import { PanelBase } from './components/PanelBase';
import { PanelContents } from './components/PanelContents';
import { PanelErrorFooter } from './components/PanelError';
import { PanelSubmit } from './components/PanelSubmit';

const validationSchema = yup.object().shape({
  organizationName: yup
    .string()
    .max(50, i18n.t('Naam mag minimaal 50 karakters bevatten'))
    .required(i18n.t('Dit veld is verplicht')),
  isActive: yup.boolean().required(),
  brandingType: yup.string(),
});

interface PanelProps {
  isOpened: boolean;
  onClose: () => void;
  onSubmit: (dto: CreateOrganizationDto) => void;
  title: string;
  description: string;
  organization?: Organization;
  isLoading: boolean;
  error?: SerializedError | FetchBaseQueryError | undefined;
}

export const OrganisationUpsertPanel: React.FC<PanelProps> = ({
  isOpened,
  onClose,
  onSubmit,
  title,
  description,
  organization,
  isLoading,
  error,
}: PanelProps) => {
  const onSliderClosed = () => {
    onClose();
  };
  const { t } = useTranslation();
  const [logoPreview, setLogoPreview] = useState(organization?.logoUri);
  const [logo, setLogo] = useState<string>();

  const { getInputProps, open, getRootProps } = useDropzone({
    accept: 'image/jpeg, image/png',
    maxFiles: 1,
    onDrop: async (acceptedFiles) => {
      // do nothing if no files
      if (acceptedFiles.length === 0) {
        return;
      }
      const first = acceptedFiles[0];
      const base64 = await getBase64(first, true);
      const preview = await getBase64(first, false);
      setLogoPreview(preview);
      setLogo(base64);
    },
  });

  return (
    <PanelBase isOpened={isOpened}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          organizationName: organization?.name ?? '',
          organizationLogo: logo,
          brandingOptions: organization?.brandingType ?? 'LogoAndName',
          isActive: true,
        }}
        onSubmit={(values) => {
          onSubmit({
            name: values.organizationName,
            logoContents: !isValidHttpUrl(logo) ? logo : null,
            isActive: values.isActive,
            brandingType: values.brandingOptions as BrandingType,
          });
        }}
      >
        {({
          errors,
          touched,
          //  values, setValues
        }) => (
          <Form className='flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl'>
            <PanelContents title={title} description={description} onSliderClosed={onSliderClosed}>
              <div>
                <label htmlFor='project_name' className='block text-sm font-medium text-gray-900'>
                  {t('Naam organisatie')}
                </label>
                <div className='mt-1'>
                  <Field
                    autoComplete='false'
                    spellCheck={false}
                    type='text'
                    name='organizationName'
                    id='organizationName'
                    className={`block w-full border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-yellow-500 focus:border-yellow-500 ${
                      errors.organizationName && touched.organizationName ? 'border-red-500' : ''
                    }`}
                  />
                  {errors.organizationName && touched.organizationName && (
                    <span className='text-xs italic text-yellow-800'>{errors.organizationName}</span>
                  )}
                </div>
              </div>
              <div>
                <label htmlFor='description' className='block text-sm font-medium text-gray-900'>
                  {t('Logo organisatie')}
                </label>
                <div className='mt-1'>
                  {!logo && !logoPreview && (
                    <div
                      className='flex justify-center max-w-lg px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'
                      {...getRootProps()}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <input {...getInputProps()} />

                      <div className='space-y-1 text-center'>
                        <svg
                          className='w-12 h-12 mx-auto text-gray-400'
                          stroke='currentColor'
                          fill='none'
                          viewBox='0 0 48 48'
                          aria-hidden='true'
                        >
                          <path
                            d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                            strokeWidth={2}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <div className='flex text-sm text-gray-600 cursor-pointer' onClick={open}>
                          <label
                            htmlFor='file-upload'
                            className='relative font-medium text-yellow-600 bg-white rounded-md hover:text-yellow-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-yellow-500'
                          >
                            <span>Upload a file</span>
                          </label>
                          <p className='pl-1'>or drag and drop</p>
                        </div>
                        <p className='text-xs text-gray-500'>PNG, JPG, GIF up to 10MB</p>
                      </div>
                    </div>
                  )}
                  {(!!logo || !!logoPreview) && (
                    <div className='flex flex-row items-center space-x-2'>
                      <button
                        className='px-2 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500'
                        onClick={() => {
                          setLogo(undefined);
                          setLogoPreview(undefined);
                        }}
                      >
                        <TrashIcon className='w-5 h-5 text-red-600' />
                      </button>
                      {!!logoPreview && <img className='h-16 max-w-full' src={logoPreview} />}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <label htmlFor='branding' className='block text-sm font-medium text-gray-900'>
                  {t('Branding')}
                </label>
                <div className='mt-1'>
                  <Field
                    as='select'
                    name='brandingOptions'
                    id='brandingOptions'
                    className={`block w-full border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-yellow-500 focus:border-yellow-500 ${
                      errors.brandingOptions && touched.brandingOptions ? 'border-red-500' : ''
                    }`}
                  >
                    <option value='LogoAndName'>{t('Logo en naam')}</option>
                    <option value='LogoOnly'>{t('Enkel Logo')}</option>
                    <option value='NameOnly'>{t('Enkel Naam')}</option>
                  </Field>
                  {errors.brandingOptions && touched.brandingOptions && (
                    <span className='text-xs italic text-yellow-800'>{errors.brandingOptions}</span>
                  )}
                </div>
              </div>
              {/* <div>
                <div className='flex flex-row items-center'>
                  <Switch
                    checked={values.isActive}
                    onChange={(value) =>
                      setValues({
                        ...values,
                        isActive: value,
                      })
                    }
                    className={`${
                      values.isActive ? 'bg-yellow-600' : 'bg-gray-200'
                    } relative inline-flex items-center h-6 rounded-full w-11`}
                  >
                    <span
                      className={`${
                        values.isActive ? 'translate-x-6' : 'translate-x-1'
                      } inline-block w-4 h-4 transform bg-white rounded-full transition ease-in-out duration-200`}
                    />
                  </Switch>
                  <label htmlFor='isActive' className='ml-4 text-sm font-medium text-gray-900'>
                    {t('De organisatie, bijbehorende afdelingen en rapportages zijn actief')}
                  </label>
                </div>
              </div> */}
            </PanelContents>
            <PanelErrorFooter error={error} />
            <PanelSubmit isLoading={isLoading} onSliderClosed={onSliderClosed} />
          </Form>
        )}
      </Formik>
    </PanelBase>
  );
};
