/* eslint-disable @typescript-eslint/no-empty-function */
import { Switch } from '@headlessui/react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import i18n from '../../i18n/config';
import { UploadTask } from '../services/apiGenerated';
import { PanelBase } from './components/PanelBase';
import { PanelContents } from './components/PanelContents';
import { PanelErrorFooter } from './components/PanelError';
import { PanelSubmit } from './components/PanelSubmit';

const validationSchema = yup.object().shape({
  reportName: yup
    .string()
    .max(50, i18n.t('Naam mag minimaal 50 karakters bevatten'))
    .required(i18n.t('Dit veld is verplicht')),
  description: yup.string().required(i18n.t('Dit veld is verplicht')),
  dueDate: yup.date().required(i18n.t('Dit veld is verplicht')),
  isActive: yup.boolean().required(i18n.t('Dit veld is verplicht')),
});

interface PanelProps {
  isOpened: boolean;
  onClose: () => void;
  onSubmit: (dto: Partial<UploadTask>) => void;
  title: string;
  description: string;
  uploadTask?: UploadTask;
  isLoading: boolean;
  error?: SerializedError | FetchBaseQueryError | undefined;
}

export const UploadTaskUpsertPanel: React.FC<PanelProps> = ({
  isOpened,
  onClose,
  onSubmit,
  title,
  description,
  uploadTask,
  isLoading,
  error,
}: PanelProps) => {
  const onSliderClosed = () => {
    onClose();
  };
  const { t } = useTranslation();

  let parsedDate = '';
  if (uploadTask?.dueDate) {
    const dateObj = new Date(uploadTask.dueDate);
    parsedDate = dateObj.toISOString().split('T')[0];
  }

  return (
    <PanelBase isOpened={isOpened}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          reportName: uploadTask?.reportName ?? '',
          description: uploadTask?.description ?? '',
          isActive: uploadTask?.isActive ?? false,
          dueDate: parsedDate,
        }}
        onSubmit={(values) => {
          onSubmit({
            reportName: values.reportName,
            description: values.description,
            isActive: values.isActive,
            dueDate: values.dueDate,
            status: 'IsWaitingForUpload',
          });
        }}
      >
        {({ errors, touched, values, setValues }) => (
          <Form className='flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl'>
            <PanelContents title={title} description={description} onSliderClosed={onSliderClosed}>
              <div>
                <label htmlFor='reportName' className='block text-sm font-medium text-gray-900'>
                  {t('Rapportage naam')}
                </label>
                <div className='mt-1'>
                  <Field
                    autoComplete='false'
                    spellCheck={false}
                    type='text'
                    name='reportName'
                    id='reportName'
                    className={`block w-full border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-yellow-500 focus:border-yellow-500 ${
                      errors.reportName && touched.reportName ? 'border-red-500' : ''
                    }`}
                  />
                  {errors.reportName && touched.reportName && (
                    <span className='text-xs italic text-yellow-800'>{errors.reportName}</span>
                  )}
                </div>
              </div>
              <div>
                <label htmlFor='description' className='block text-sm font-medium text-gray-900'>
                  {t('Omschrijving data upload')}
                </label>
                <div className='mt-1'>
                  <Field
                    component='textarea'
                    type='text'
                    autoComplete='false'
                    spellCheck={false}
                    name='description'
                    id='description'
                    rows={3}
                    className={`h-40 block w-full border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-yellow-500 focus:border-yellow-500 ${
                      errors.description && touched.description ? 'border-red-500' : ''
                    }`}
                  />
                  {errors.description && touched.description && (
                    <span className='text-xs italic text-yellow-800'>{errors.reportName}</span>
                  )}
                </div>
              </div>
              <div>
                <label htmlFor='dueDate' className='block text-sm font-medium text-gray-900'>
                  {t('Uiterste aanleverdatum')}
                </label>
                <div className='mt-1'>
                  <Field
                    type='date'
                    name='dueDate'
                    id='dueDate'
                    autoComplete='false'
                    spellCheck={false}
                    className={`block w-full border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-yellow-500 focus:border-yellow-500 ${
                      errors.dueDate && touched.dueDate ? 'border-red-500' : ''
                    }`}
                  />
                  {errors.dueDate && touched.dueDate && (
                    <span className='text-xs italic text-yellow-800'>{errors.reportName}</span>
                  )}
                </div>
              </div>
              <div>
                <div className='flex flex-row items-center'>
                  <Switch
                    checked={values.isActive}
                    onChange={(value) =>
                      setValues({
                        ...values,
                        isActive: value,
                      })
                    }
                    className={`${
                      values.isActive ? 'bg-yellow-600' : 'bg-gray-200'
                    } relative inline-flex items-center h-6 rounded-full w-11`}
                  >
                    <span
                      className={`${
                        values.isActive ? 'translate-x-6' : 'translate-x-1'
                      } inline-block w-4 h-4 transform bg-white rounded-full transition ease-in-out duration-200`}
                    />
                  </Switch>
                  <label htmlFor='isActive' className='ml-4 text-sm font-medium text-gray-900'>
                    {t('Deze uploadtaak is zichtbaar voor de klant.')}
                  </label>
                </div>
              </div>
            </PanelContents>
            <PanelErrorFooter error={error} />
            <PanelSubmit isLoading={isLoading} onSliderClosed={onSliderClosed} />
          </Form>
        )}
      </Formik>
    </PanelBase>
  );
};
